import React from 'react';
import './BasicLayout.scss'

interface BaseLayoutProps {
  children: JSX.Element,
  className?: string
}

const BasicLayout = (props: BaseLayoutProps) => {
  return (
    <section className={`base-layout ${props.className ?? ''}`}>
      <main className="base-content">
        <section className="content p-3 p-sm-0 row justify-content-center d-flex">
          {props.children}
        </section>
      </main>
    </section>
  );
};

export default BasicLayout;
