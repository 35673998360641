import {
  IncomingSideCard,
  SideCardHeader,
  InspectionReportSideCard,
  ServicesSideCard,
  DetailCard,
  OutgoingSideCard,
  Status
} from "@profuse-studio/profuse-inventory-uix";
import React, {useEffect} from "react";
import './InventorySideCard.scss';
import Helper from "../../utilities/Helper";
import {vehicleWarehouseDetails} from "../../services/GraphQL/schemas/vehicle_order";


interface InventorySideCardProps {
  cardHeight?: string;
  data: vehicleWarehouseDetails;
  onMobileBack?: () => void;
  onInspectionReportClicked: (id: number) => void;
}

interface EmptyDataProps {
  msg?: string;
}

const EmptyData = (props: EmptyDataProps) => {
  return (
    <div className={`no-content`}>
      <p className={`font-12`}>{props.msg ?? 'Please select an order from the left to see the details'}</p>
    </div>
  )
}

const InventorySideCardComponent = (props: InventorySideCardProps) => {
  //const [inventory, setInventory] = useState<vehicleWarehouseDetails>(props.data || new vehicleWarehouseDetails());
  const incomingMoves = props.data?.moves.filter(move => move.incoming).sort(Helper.sortByDate);
  const outgoingMoves = props.data?.moves.filter(move => !move.incoming).sort(Helper.sortByDate);

  function calculateStatus(data: vehicleWarehouseDetails){
    if(!data.last_move && data.moves){
      data.last_move = data.moves.filter(x=>x.completed).sort((a, b)=> new Date(a.time).getTime() < new Date(b.time).getTime() ? 1 : -1)[0];
    }
    if(data.last_move && data.last_move.incoming) {
      return (<Status label={'PARKED'} bgColor={'#D2F0B9'} color={'#58B50F'}/>);
    } else {
      return (<Status label={'IN TRANSIT'} bgColor={'#CEE8F4'} color={'#24A5E2'}/>);
    }
  }
  return (
    <>
      <div
        className={`inventory-side-card p-3 flex-1-container ${props.data?.id ? '' : 'justify-content-center align-items-center'}`}
        style={{height: props.cardHeight ?? 'auto'}}>
        {
          props.data?.id ?
            <>
              <div className="mb-3 row">
                <SideCardHeader
                  vehicle={`${props.data.vehicle.year} ${props.data.vehicle.make} ${props.data.vehicle.model}`}
                  vin={props.data.vehicle.vin}
                  onBack={props.onMobileBack}
                  address={props.data.current_location? `${props.data.current_location}` : ""}
                  status={calculateStatus(props.data)}/>
              </div>

              <section className="flex-1-container">

                {
                  incomingMoves?.length <= 0 ?
                    <DetailCard title={'Incoming'} isEmpty={true}>
                      <p className="font-12 text-gray">There’s no current incoming move for this vehicle yet</p>
                    </DetailCard>
                    : // OR
                    ''
                }
                {
                  incomingMoves && incomingMoves.map((move, i) => {
                    return (
                      <div key={`incoming-${i}`} className="inventory-side-card-incoming mb-3">
                        <IncomingSideCard showTitle={i === 0}
                                          driverName={move.carrier.driver}
                                          carrierName={move.carrier.name}
                                          date={Helper.FORMAT.ParseDate(move.time)}
                                          address={move.location?.city ?? ''}
                                          hour={Helper.FORMAT.ParseTime(move.time)}
                                          street={move.location?.street ?? ''}
                                          notes={move.note}/>
                      </div>
                    );
                  })
                }

                <>
                  <hr/>
                  {
                    outgoingMoves && outgoingMoves.map((move, i) => {
                      return (
                        <div key={`outgoing-${i}`} className="inventory-side-card-outgoing mb-3">
                          <OutgoingSideCard
                            showTitle={i === 0}
                            driverName={move.carrier.driver}
                            carrierName={move.carrier.name}
                            date={Helper.FORMAT.ParseDate(move.time)}
                            address={move.location?.city ?? ''}
                            hour={Helper.FORMAT.ParseTime(move.time)}
                            street={move.location?.street ?? ''}
                          />
                        </div>
                      )
                    })
                  }
                  {
                    outgoingMoves?.length <= 0 ?
                      <DetailCard title={'Outgoing'} isEmpty={true}>
                        <p className="font-12 text-gray">There’s no current outgoing move for this vehicle yet</p>
                      </DetailCard>
                      : // OR
                      ''
                  }
                </>


                {
                  props.data.inspections && props.data.inspections.length ? props.data.inspections.map((inspection, i) => {
                      return (
                        <>
                          <hr/>
                          <div key={i} className="inventory-side-card-inspection mb-3">
                            <InspectionReportSideCard showTitle={i === 0}
                                                      hour={Helper.FORMAT.ParseTime(inspection.time)}
                                                      date={Helper.FORMAT.ParseDate(inspection.time)}
                                                      showDetailBtn={true}
                                                      onDetailClick={() => {
                                                        console.log('detail click')
                                                      }}
                                                      onClick={() => {
                                                        props.onInspectionReportClicked(inspection.id)
                                                      }}
                                                      label={inspection.type}/>
                          </div>
                        </>
                      )
                    })
                    : // OR
                    ''
                }


                {
                  props.data.inventory_services && props.data.inventory_services.length ? props.data.inventory_services.map((service, i) => {
                      return (
                        <>
                          <hr/>

                          <div key={i} className="inventory-side-card-service mb-3">
                            <ServicesSideCard showTitle={i === 0}
                                              date={Helper.FORMAT.ParseDate(service.time_start)}
                                              dateAgo={Helper.FORMAT.DaysAgo(service.time_start)}
                                              serviceName={service.name}
                                              service={`SC-${service.id}`}
                                              isCompleted={true}/>
                          </div>
                        </>
                      )
                    })
                    : // OR
                    ''
                }
              </section>
            </>
            :
            <EmptyData/>
        }
      </div>
    </>
  );
}

export const InventorySideCard = React.memo(InventorySideCardComponent);
