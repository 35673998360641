import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {
  Table,
  ICONS,
  Svg,
  IColumnProps,
  VehicleLightCard,
  AddressTitle, ClickOutsideListener, SideActionManager, HistoryItemList
} from "@profuse-studio/profuse-inventory-uix";
import {
  clearVehicleDetailsError,
  getVehicleDetailsAction,
  stopVehicleDetailsAction,
} from "../../redux/actions/vehicleDetails.action";
import {AllStates} from "../../redux/reducers";
import {useDispatch, useSelector} from "react-redux";
import {IVehicleDetails} from "../../redux/reducers/vehicleDetails.reducer";
import {Queries} from "../../services/GraphQL/queries";
import Helper from "../../utilities/Helper";
import {
  vehicleWarehouseDetails,
  warehouseOrder,
} from "../../services/GraphQL/schemas/vehicle_order";
import {InventorySideCard} from "../../components/InventorySideCard/InventorySideCard";
import "./vehicleDetails.scss";
import {GridLoader} from "../../components/Spinner/Grid";
import {
  InventoryOrder,
  Vehicle,
} from "../../services/GraphQL/schemas/vehicle";
import moment from "moment";
import {IMediaQuery, MediaContext} from "../../context/MediaContext/MediaContext";

const tableColumns: IColumnProps[] = [
  {
    flex: 2,
    label: "Dates",
    type: "html",
    name: "dates",
  },
  {
    flex: 2,
    label: "Location",
    name: "location",
    sort_by: "warehouse_name",
  },
  {
    flex: 2,
    type: "react-component",
    label: "EXTRAS",
    name: "extras",
  },
];

interface IVehicleTableRow {
  id: number;
  dates: string;
  dates_no_format: string;
  location: [string, string];
  warehouse_name: string;
  extras: any;
}

export const VehicleDetails = () => {
  const [loadingForMap, setLoadingForMap] = useState<boolean>(false);
  const dispatch = useDispatch();
  let params = useParams();
  const vin = params.vin;
  const [selectedID, setSelectedID] = useState<number>();
  const [tableData, setTableData] = useState<IVehicleTableRow[]>([]);
  const [vehicleWarehouseDetails, setVehicleWarehouseDetails] =
    useState<vehicleWarehouseDetails | null>(null);
  const [vehicleDetails, setVehicleDetails] = useState<Vehicle | null>(null);
  const [staticMap, setStaticMap] = useState<{ id: string, time: string | null, link: string, address: string }>({
    id: '',
    time: null,
    link: '',
    address: ''
  });
  const [showTableAsList, setShowTableAsList] = useState(false);
  const {isSm, isMd} = React.useContext(MediaContext) as IMediaQuery;

  const {loading, orders, error} = useSelector<AllStates>(
    (states) => states.vehicleDetails
  ) as IVehicleDetails;

  if (error) {
    console.log(
      "Error from Observable getVehicleDetailsAction: ",
      error.message
    );
    dispatch(clearVehicleDetailsError() as any);
  }

  useEffect(() => {
    if (!loading && vin) {
      dispatch(getVehicleDetailsAction(vin) as any);
    }
    Helper.WhenLocationChanges = (link) => {
      if (!link.startsWith("/vehicle/")) {
        stopVehicleDetailsAction();
      }
    };
  }, []);

  useEffect(() => {
    Helper.SetLoading(loading);
  }, [loading]);

  function calculateDates(order: InventoryOrder) {
    console.log(order)
    let moves =
      order.moves.sort((a, b) =>
        new Date(a.time).getTime() > new Date(b.time).getTime() ? 1 : -1
      ) || [];
    let l = moves.length;
    if (l > 0) {
      return `<p>${moment(new Date(moves[0].time)).format(
        "MM/DD/YYYY"
      )}<br/> - ${
        l > 1 ? moment(new Date(moves[l - 1].time)).format("MM/DD/YYYY") : ""
      }</p>`;
    } else {
      return `<p> - <p/>`;
    }
  }

  function fillTable(data: InventoryOrder[]) {
    console.log(data);
    let rows: IVehicleTableRow[] = data.map((x) => ({
      id: x.vehicle_order_id,
      dates: calculateDates(x),
      dates_no_format: calculateDates(x).replace(/<\/?[^>]+(>|$)/g, ""),
      location: [
        x.warehouses[0]?.name || "-",
        Helper.FORMAT.Address(x.warehouses[0]?.address, "city_state"),
      ],
      warehouse_name: x.warehouses[0]?.name || "-",
      extras: <>
        {x.has_inventory_services ? <Svg src={ICONS.Eye} className="me-1"  onClick={() => {}}/> : <Svg src={ICONS.Eye} className="me-1 table-icon inactive "/>}
        {x.has_pending_inspection ?  <Svg src={ICONS.Edit} className="me-1" onClick={() => {}}/> : <Svg src={ICONS.Edit} className="me-1 table-icon inactive"/>}
      </>
    }));
    setTableData(rows);
  }

  async function fillWarehouseDetailsWindow() {
    Helper.SetLoading(true);
    try {
      let res = await Queries.get_vehicle_order_warehouse_details({
        id: selectedID || -1,
      });
      setVehicleWarehouseDetails(res);
    } catch (ex: any) {
      console.log("Error in fillWarehouseDetailsWindow: ", ex.message);
    }
    Helper.SetLoading(false);
  }

  async function getVehicleInfo() {
    if (!vin) {
      return;
    }
    Helper.SetLoading(true);
    try {
      let res = await Queries.get_vehicle_details({vin});
      setVehicleDetails(res);
    } catch (ex: any) {
      console.log("Error in getVehicleInfo: ", ex.message);
    }
    Helper.SetLoading(false);
  }

  async function getStaticMap() {
    if (!vin) {
      return;
    }
    setLoadingForMap(true);
    try {
      let res = await Queries.get_vin_location_static_image({vin});
      setStaticMap(res);
    } catch (ex: any) {
      console.log("Error in getStaticMap: ", ex.message);
    }
    setLoadingForMap(false);
  }

  useEffect(() => {
    console.log('orders');
    console.log(orders);
    fillTable(orders);
    if (selectedID) {
      fillWarehouseDetailsWindow();
    }
    getVehicleInfo();
    getStaticMap();
  }, [orders]);

  useEffect(() => {
    if (selectedID) {
      fillWarehouseDetailsWindow();
    }
  }, [selectedID]);

  async function handleInspectionReportClicked(inspection_id: number) {
    Helper.SetLoading(true);
    try {
      let res = await Queries.get_inspection_report_link({id: inspection_id});
      console.log(res)
      if (!res.success) {
        throw new Error(res.message);
      } else {
        window.open(res.message);
      }
    } catch (ex: any) {
      console.log("Error in handleInspectionReportClicked :" + ex.message);
    }
    Helper.SetLoading(false);
  }

  return (
    <section className="flex-1-container mt-sm-0 mt-md-4">
      <section
        className="row flex-1-container row-container pb-24 flex-column flex-md-row justify-content-start justify-content-md-between">
        <div
          className={`row-container pb-24  col-md-3 d-none d-md-flex`}
          style={{flexDirection: "column"}}
        >
          {vehicleDetails && (
            <VehicleLightCard
              description={`${vehicleDetails.year} ${vehicleDetails.make} ${vehicleDetails.model}`.trim()}
              showLink={isMd || isSm}
              licencePlate={vehicleDetails.license_plate || "-"} make={''}
              vin={vehicleDetails.vin.substring(vehicleDetails.vin.length - 6)}
            />
          )}
          <div
            className="col-12  br-12 mt-40 vehicle-details-map-div d-flex flex-column"
            style={{backgroundColor: "#fff", cursor: "pointer"}}
            onClick={(evt) => {
              Helper.Navigation.NavigateTo(`/map/${staticMap.id}`);
            }}
          >
            {loadingForMap && (
              <div id="loading-for-map">
                <GridLoader remSize={3}/>
              </div>
            )}

            {
              staticMap.link ? <img
                src={staticMap.link}
                className="vehicle-details-map br-t-12"
                alt="log-out-icon"
              /> : <></>
            }
            <div className={'d-flex align-items-center justify-content-end p-2 pb-3 px-4'}>
              <AddressTitle address={staticMap.address}/>
              {/*<p className="font-14 font-medium px-2 mt-2 mb-0">{staticMap.address}</p>*/}
            </div>
            <div className="font-10 px-4 ms-4  pb-2">{staticMap.time ? `At ${moment(new Date(staticMap.time)).format('MM/DD/YYYY HH:mm a')} (${Helper.FORMAT.DaysAgo(staticMap.time)})`:'-'}</div>
          </div>
        </div>

        {
          (vehicleDetails && (isSm || isMd)) ? (
            <div className={`row-container pb-24  col-md-5 d-flex   `}>
              <div className="col-12 flex-1-container pt-24">
                <VehicleLightCard
                  description={`${vehicleDetails.year} ${vehicleDetails.make} ${vehicleDetails.model}`.trim()}
                  showLink={isMd || isSm}
                  licencePlate={vehicleDetails.license_plate || "-"} make={''}
                  vin={vehicleDetails.vin.substring(vehicleDetails.vin.length - 6)}
                  onShowLinkClick={() => {
                    Helper.Navigation.NavigateTo(`/map/${staticMap.id}`);
                  }}
                />
              </div>
            </div>
          ) : <></>
        }

        {!isSm ? (
          <div className={`row-container pb-24  col-md-5 d-flex   `}>
            <div
              className="col-12 flex-1-container pt-24 br-12 "
              style={{backgroundColor: "#fff"}}
            >
              <div>
                <p className="font-14 font-medium ms-3">{`Order history (${tableData.length})`}</p>
              </div>
              <div>
                <Table
                  showAsList={showTableAsList}
                  listItemTemplateKey={"listTemplate"}
                  columns={tableColumns}
                  menuOptions={[
                    {
                      label: "See details",
                      icon: ICONS.Eye,
                      action: () => {
                      },
                    },
                  ]}
                  rowHeight={80}
                  rows={tableData}
                  tableHeight="70vh"
                  onRowClicked={(row: IVehicleTableRow) => {
                    if (selectedID === row.id) {
                      fillWarehouseDetailsWindow();
                    } else {
                      setSelectedID(row.id);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <section className="item-list flex-1-container">
            {
              tableData?.map(item => {
                return (
                  <HistoryItemList
                    title={item.warehouse_name}
                    subtitle={item.location[1]}
                    sideInfo={item.dates_no_format.split('-')}
                    onClick={() => {
                      if (selectedID === item.id) {
                        fillWarehouseDetailsWindow();
                      } else {
                        setSelectedID(item.id);
                      }
                    }
                    }
                  />
                )
              })
            }
          </section>
        )}

        {
          !isSm && !isMd ? (
            <div className={`row-container pb-24 col-4 d-flex  `}>
              <div
                className="col-12 flex-1-container br-12"
                style={{backgroundColor: "#fff"}}
              >
                <InventorySideCard
                  data={vehicleWarehouseDetails as vehicleWarehouseDetails}
                  onInspectionReportClicked={(id: number) =>
                    handleInspectionReportClicked(id)
                  }
                />
              </div>
            </div>
          ) : <></>
        }
      </section>

      {
        isSm || isMd ?
          <>
            <ClickOutsideListener onClickAway={() => {
              setVehicleWarehouseDetails(null)
            }}>
              <div>
                <SideActionManager opened={!!vehicleWarehouseDetails?.id}>
                  <InventorySideCard
                    onMobileBack={() => {
                      setVehicleWarehouseDetails(null)
                    }}
                    data={vehicleWarehouseDetails as vehicleWarehouseDetails}
                    onInspectionReportClicked={(id: number) => handleInspectionReportClicked(id)}/>
                </SideActionManager>
              </div>
            </ClickOutsideListener>
          </>
          : // OR
          ''
      }

    </section>
  );
};
