import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { Home } from "./pages/Home/Home";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { PasswordReset } from "./pages/PasswordReset/PasswordReset";
import Helper from "./utilities/Helper";

import "./App.scss";
import { GridLoader } from "./components/Spinner/Grid";
import { profileSP } from "./services/GraphQL/schemas/customer_profile";

function App() {
  let navigate = useNavigate();
  let location = useLocation();
  let load = useState<boolean>(false);
  let user = useState<profileSP>(new profileSP());
  Helper.SetNavigator(navigate, location);
  Helper.SetLoader(load);
  Helper.SetUserState(user);

  useEffect(() => {
    Helper.Navigation.LocationChanged();
  }, [location]);

  const Middleware = () => {
    const [route,setRoute] = useState<string>('');
    let ref = useRef<boolean>(false);
    
    useEffect(() => {
      if(localStorage.getItem('profuseinventory')?.length){
        setRoute('/inventory');
      } else{
        setRoute('/login');
      }
    },[]);

    useEffect(() => {
      if(route){
        navigate(route);
      }
    },[route]);

    return <h1 style={{position: 'absolute',top:'20%',width:'100%',textAlign: 'center', fontSize:'3rem'}}>Authorizing...</h1>
  }

  return (
    <>
      {load[0] && <GridLoader remSize={3}/>}
      <Routes>
        <Route path="/404" element={<h1>404 NOT FOUND</h1>} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/*" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
        <Route path="/passwordreset/*" element={<PasswordReset />}></Route>
        <Route path="/" element={<Middleware />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
