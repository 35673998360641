import useMediaQuery from "../../hooks/useMediaQuery";
import * as React from 'react';

export interface IMediaQuery {
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  isXll: boolean;
}

const defaultValues: IMediaQuery = {
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: false,
  isXll: false,
}

export const MediaContext = React.createContext<IMediaQuery | null>(defaultValues);

const MediaProvider = ({children}: { children: JSX.Element }) => {
  const [userSetQuery, setUserSetQuery] = useMediaQuery(
    "screen and (max-width: 767px)"
  );
  const [isSm] = useMediaQuery("screen and (max-width: 600px)");
  const [isMd] = useMediaQuery("screen and (max-width: 960px)");
  const [isLg] = useMediaQuery("screen and (max-width: 1280px)");
  const [isXl] = useMediaQuery("screen and (max-width: 1920px)");
  const [isXll] = useMediaQuery("screen and (max-width: 2400px)");
  const [isTouchScreen] = useMediaQuery(
    "screen and (hover: none) and (pointer: coarse)"
  );

  return <MediaContext.Provider value={{ isSm, isMd, isLg, isXl, isXll }}>{children}</MediaContext.Provider>;
}

export default MediaProvider;

