import React, { useEffect, useState, ReactComponentElement } from "react";
import "./history.scss";
import {
  SearchField,
  Table,
  ICONS,
  IColumnProps,
  Status,
  Button,
  MobileHeaderFilter,
} from "@profuse-studio/profuse-inventory-uix";
import {MobileListItem} from "@profuse-studio/profuse-ui";
import {
  IMediaQuery,
  MediaContext,
} from "../../context/MediaContext/MediaContext";
import Helper from "../../utilities/Helper";
import { Queries } from "../../services/GraphQL/queries";
import { VehicleHistory } from "../../services/GraphQL/schemas/vehicle";
import moment from "moment";

interface HistoryTableRow {
  vehicle: [string, string];
  model: string;
  date_time: [string, string, string];
  vin: string;
  vin_formatted: string;
  status: any;
}

const tableColumns: IColumnProps[] = [
  {
    flex: 3,
    label: "Last move",
    name: "date_time",
  },
  {
    flex: 3,
    label: "Vehicle",
    name: "vehicle",
    sort_by: "model",
  },
  {
    flex: 2,
    label: "Status",
    name: "status",
  },
  {
    flex: 3,
    label: "Vin",
    name: "vin",
  },
];

export const History = () => {
  const [inventoryTableData, setInventoryTableData] = useState<
    HistoryTableRow[]
  >([]);
  const [showTableAsList, setShowTableAsList] = useState(false);
  const [searchVin, setSearchVin] = useState<string | null>("");
  const [searchVehicleDetails, setSearchVehicleDetails] = useState<
    string | null
  >("");
  const [dataTimePikerClick, SetDataTimePikerClick] = useState<boolean>(false);

  const { isSm, isMd } = React.useContext(MediaContext) as IMediaQuery;

  const handleClearSearch = () => {
    setSearchVin(null);
    setSearchVehicleDetails(null);
  };

  useEffect(() => {
    handleClearSearch();
  },[isSm])

  useEffect(() => {
    if (searchVin == null) {
      setSearchVin("");
    }
    if (searchVehicleDetails == null) {
      setSearchVehicleDetails("");
    }
  }, [searchVin, searchVehicleDetails]);

  async function performSearch() {
    Helper.SetLoading(true);
    try {
      let res = await Queries.search_inventory_vehicle_history({
        vin: searchVin || "",
        description: searchVehicleDetails || "",
        start_date: "",
        end_date: "",
      });
      fillTable(res);
    } catch (ex: any) {
      console.log("Error in performSearch: ", ex.message);
    }
    Helper.SetLoading(false);
  }

  function fillTable(data: VehicleHistory[]) {
    let rows: HistoryTableRow[] = data.map((x) => ({
      status: x.status === "parked" ? (
      <Status label={'PARKED'} bgColor={'#D2F0B9'} color={'#58B50F'}/>
      ) : (
      <Status label={'IN TRANSIT'} bgColor={'#CEE8F4'} color={'#24A5E2'}/>),
      vin: x.vin,
      date_time: [
        moment(new Date(x.last_move)).format("MM/DD/YYYY"),
        moment(new Date(x.last_move)).format("HH:mm a"),
        Helper.FORMAT.USDate(new Date(x.last_move), "dd"),
      ],
      model: x.description.split(' ').filter(x=>x).reverse()[0] || '',
      vehicle: [
        x.description.split(' ').filter(x=>x).reverse()[0] || ''
        ,x.description
      ] as [string, string],
      vin_formatted: `${x.vin.substring(0, x.vin.length - 6)} - ${x.vin.substring(
        x.vin.length - 6
      )}`
    }));
    setInventoryTableData(rows);
  }

  useEffect(() => {
    performSearch();
  }, []);


  document.onkeydown = function (this: GlobalEventHandlers, ev: KeyboardEvent) {
    if(ev.key === 'Enter'){
      performSearch();
    };
  };
  return (
    <>
      {!isSm ? (
        <section className="history-component flex-1-container">
          <section className="mt-20">
            <div className="row justify-content-between">
              <div className="col-10">
                <div className="row justify-content-start">
                  {/* <div
                    className="col-sm-4 col-md-3 align-items-center d-flex"
                    style={{ flex: "0 1" }}
                  >
                    <DatePickerButton range={true} />
                  </div> */}
                  <div className="col-sm-3 col-md-4">
                    {searchVin != null && (
                      <SearchField
                        background={`#fff`}
                        placeholder={`Search for Vin`}
                        onChange={(s: string) => setSearchVin(s)}
                        value={searchVin}
                      />
                    )}
                  </div>
                  <div className="col-sm-5 col-md-5">
                    {searchVehicleDetails != null && (
                      <SearchField
                        background={`#fff`}
                        placeholder={`Search for year/make/model`}
                        onChange={(s: string) => setSearchVehicleDetails(s)}
                        value={searchVehicleDetails}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="d-flex justify-content-end">
                  <Button
                    small
                    label={"Clear Search"}
                    type={"flat"}
                    btnColor={"black"}
                    className={"align-self-center cBlack "}
                    onClick={handleClearSearch}
                  />
                  <Button
                    small
                    label={"Search"}
                    type={"fill"}
                    btnColor={"black"}
                    onClick={() => performSearch()}
                    className={"cBlack "}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="table-content mt-20 tablet-div flex-1-container">
            <div className={`row flex-1-container row-container pb-24`}>
              <div className="col-12 col-md-12 d-flex flex-1-container">
                <div className={`bg-white br-12 flex-1-container`}>
                  <Table
                    showAsList={showTableAsList}
                    listItemTemplateKey={"listTemplate"}
                    columns={tableColumns}
                    menuOptions={[
                      {
                        label: "See details",
                        icon: ICONS.Eye,
                        action: (item:{selected:{row:HistoryTableRow}}) => {
                          if(item.selected?.row?.vin){
                            let link = `/vehicle/${item.selected.row.vin}`
                            Helper.Navigation.NavigateTo(link);
                          }
                        },
                      },
                    ]}
                    rowHeight={80}
                    rows={inventoryTableData}
                    onRowClicked={(row) => {}}
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
      ) : (
        // OR
        <section className="history-component-mobile flex-1-container">
          <header className={`row`}>
            <MobileHeaderFilter
              showInputSearch={true}
              showSearchButton={true}
              onVinMakeYearChange={(val?:string) => setSearchVehicleDetails(val||'')}
              onVinChange={(val?:string) => setSearchVin(val||'')}
              handleSearch={() => performSearch()}
              handleReset={() => handleClearSearch()}>
              <></>
            </MobileHeaderFilter>
            {/*TODO: show on search or date filters applied*/}
            {/* <div className="results mt-3 d-flex justify-content-between align-items-center">
              <span className={`fs-12 fw400`}>${`3 results for XXXX`}</span>
              <span className={`fs-12 fw400`}>CLEAR SEARCH</span>
            </div> */}
          </header>

          {/*Search results*/}
          <section className="item-list mt-2 flex-1-container">
            {inventoryTableData.map((x) => {
              return (
                <MobileListItem
                  title={x.vehicle[1]}
                  subtitle = {x.vin}
                  icon={ICONS.ArrowDown}
                  bgColor={"#EB4886"}
                  sideInfo={[x.date_time[0], x.date_time[1], x.date_time[2]]}
                  onLongClick={() => {
                    Helper.Navigation.NavigateTo(`/vehicle/${x.vin}`);
                  }}
                  onClick={() => {
                    console.log("click");
                  }}
                />
              );
            })}
          </section>
        </section>
      )}
    </>
  );
};
