import React, {useEffect, useRef, useState} from "react";
import {Route, Routes} from "react-router-dom";
import Helper from "../../utilities/Helper";
import "./home.scss";
import {
  TopBar,
  MobileBottomTabs,
  ICONS
} from "@profuse-studio/profuse-inventory-uix";
import mainLogo from "../../../assets/images/JPL_logo.png";
import {MainLayout} from "../../layout";
import {Inventory} from "../Inventory/Inventory";
import {History} from "../History/History";
import {AccountInfo} from "../AccountInfo/AccountInfo";
import {Map} from "../Map/Map";
import LogOuticon from "../../../assets/images/LogOuticon.svg";
import {IMediaQuery, MediaContext} from "../../context/MediaContext/MediaContext";
import {GlobalContext, IGlobalProps} from "../../context/GlobalContext/GlobalContext";
import {VehicleDetails} from "../VehicleDetails/VehicleDetails"
// import webpack from 'webpack';
const NavButtons: { title: string; link: string }[] = [
  {
    title: "Inventory",
    link: "/inventory",
  },
  {
    title: "Map View",
    link: "/map",
  },
  {
    title: "History",
    link: "/history",
  },
];

export const Home = () => {
  const [avatarMenuOpened, setAvatarMenuOpened] = useState<boolean>(false);
  const [route, setRoute] = useState<string>(
    Helper.Navigation.GetCurrentLocation()
  );
  const {isSm, isMd} = React.useContext(MediaContext) as IMediaQuery;
  const {showMobileBottomTabs, mobileBottomTabsPosition} = React.useContext(GlobalContext) as IGlobalProps;

  const LogOutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (LogOutRef.current && !LogOutRef.current.contains(e.target)) {
        setAvatarMenuOpened(!avatarMenuOpened);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [avatarMenuOpened]);

  useEffect(() => {
    CheckToken();
    let topbar = document.getElementById("topbar");

    window.onscroll = function () {
      if (window.pageYOffset >= (topbar?.offsetTop || 0)) {
        topbar?.classList.add("sticky");
      }
      if (((window.pageYOffset === 0))) {
        topbar?.classList.remove("sticky");
      }
    };

    // window.onscroll = function () {
    //   if (window.pageYOffset >= (topbar?.offsetTop || 0)) {
    //     topbar?.classList.add("sticky");
    //   } else {
    //     topbar?.classList.remove("sticky");
    //   }
    // };
  }, []);

  // useEffect(() => {
  //   setAvatar(GetInitials);
  // }, [route]);

  async function CheckToken() {
    try {
      await Helper.Session.VerifyUser();
    } catch (ex: any) {
      console.log("Error in CheckToken: ", ex.message);
      Helper.Session.DoLogout();
    }
  }

  function handleUserLogout() {
    Helper.Session.DoLogout(false);
  }

  function handleAccountInfoPage() {
    Helper.Navigation.NavigateTo("/account")
  }

  function changeRoute(newroute: string) {
    setRoute(newroute);
    Helper.Navigation.NavigateTo(newroute);
    //window.location.reload();
  }

  const NotFound = (props: any) => {
    useEffect(() => {
      if (route === "/" || route === "") {
        Helper.Navigation.NavigateTo("/invoices");
      } else {
        Helper.Navigation.NavigateTo("/404");
      }
    }, [props.text]);
    return <h1>404</h1>;
  };

  return (
    <MainLayout>
      <>
        {
          !isSm ?
            <div className={`mt-32`} id={"topbar"}>
              <TopBar
                buttons={NavButtons}
                avatar={{
                  name: Helper.Session.getUser()?.name || '',
                  company: Helper.Session.getUser()?.customer?.name || '',
                }}
                logo={mainLogo}
                onAvatarClicked={() => setAvatarMenuOpened(true)}
                onLogoClicked={() => {
                  window.open("https://www.jplogistics.net/");
                }}
                currentRoute={route}
                onLinkClick={(link) => {
                  changeRoute(link);
                }}
                onNewChargeClick={() => {
                  changeRoute("/NewCharge");
                }}
              />
              {avatarMenuOpened && (
                <div className="log-out">
                  <div
                    className="navbar-user-logout"
                    onClick={handleUserLogout}
                    ref={LogOutRef}
                  >
                    <img src={LogOuticon} className="log-out-icon" alt="log-out-icon"/>
                    <p className="log-oute-text">Log Out</p>
                  </div>
                </div>

              )}
            </div>
            : // OR
            ''
        }
        <div className="route-body flex-1-container">
          <Routes>
          <Route path="/" element={<Inventory />} />
            <Route path="/history" element={<History />} />
            <Route path="/inventory" element={<Inventory />}/>
            <Route path="/map/:id" element={<Map />}/>
            <Route path="/map" element={<Map />}/>
            <Route path="/vehicle/:vin" element={<VehicleDetails />}/>
            <Route path="/account" element={<AccountInfo />}/>
            <Route path="/*" element={<NotFound/>}/>
          </Routes>
        </div>
        {/* <Footer /> */}
        {
          isSm && showMobileBottomTabs ?
            <MobileBottomTabs type={`animated`} position={mobileBottomTabsPosition} hide={false} tabs={[
              {icon: ICONS.User, onClick: () => handleAccountInfoPage()},
              {icon: ICONS.History, onClick: () => changeRoute('/history')},
              {icon: ICONS.House, onClick: () => changeRoute('/inventory')},
              {icon: ICONS.Map, onClick: () => changeRoute('/map')},
            ]}/>
            : // OR
            ''
        }
      </>
    </MainLayout>
  );
};
