import { Action } from "@reduxjs/toolkit";
import { GraphqlService, ObservableQueryT } from "../../services/GraphQL/graphql.service";
import { Queries } from "../../services/GraphQL/queries";
import { InventoryOrder } from "../../services/GraphQL/schemas/vehicle";
import { GET_VEHICLE_INVENTORY_ORDERS_ERROR, GET_VEHICLE_INVENTORY_ORDERS_STARTED, GET_VEHICLE_INVENTORY_ORDERS_SUCCESS } from "../types/vehicleDetails.type";


let VehicleDetailsObservable: ObservableQueryT;
const getVehicleDetailsStarted = () => ({type: GET_VEHICLE_INVENTORY_ORDERS_STARTED});
const getVehicleDetailsSuccess = (orders: InventoryOrder[]) => ({type: GET_VEHICLE_INVENTORY_ORDERS_SUCCESS, payload: orders});
const getVehicleDetailsError = (error: string) => ({type: GET_VEHICLE_INVENTORY_ORDERS_ERROR, payload: error ? new Error(error) : undefined});

export function clearVehicleDetailsError()
{
    return dispatch => dispatch(getVehicleDetailsError(''));
}

export async function stopVehicleDetailsAction()
{
    await VehicleDetailsObservable.stopPolling();
    VehicleDetailsObservable = null as any;
}

export function getVehicleDetailsAction(vin: string){
    return dispatch => {
        dispatch(getVehicleDetailsStarted());
        if(!VehicleDetailsObservable){
            VehicleDetailsObservable = GraphqlService.SendQueryObservable(Queries.GET_VEHICLE_INVENTORY_ORDERS, {vin});

            VehicleDetailsObservable.onResults.subscribe(async result => {
                if (result.error){
                    await VehicleDetailsObservable.stopPolling();
                    VehicleDetailsObservable = null as any;
                    return dispatch(getVehicleDetailsError(result.error.message));
                } else {
                    dispatch(getVehicleDetailsSuccess(result.data as InventoryOrder[]));
                }
            })
        } else {
            VehicleDetailsObservable.refetch();
        }
    }
}