import { Action } from "@reduxjs/toolkit";
import { GraphqlService, ObservableQueryT } from "../../services/GraphQL/graphql.service";
import { Queries } from "../../services/GraphQL/queries";
import { warehouseOrder } from "../../services/GraphQL/schemas/vehicle_order";
import { GET_INVENTORY_ORDERS_ERROR, GET_INVENTORY_ORDERS_STARTED, GET_INVENTORY_ORDERS_SUCCESS } from "../types/inventory.type";


let InventoryObservable: ObservableQueryT;
const getInventoryOrdersStarted = () => ({type: GET_INVENTORY_ORDERS_STARTED});
const getInventoryOrdersSuccess = (orders: warehouseOrder[]) => ({type: GET_INVENTORY_ORDERS_SUCCESS, payload: orders});
const getInventoryOrdersError = (error: string) => ({type: GET_INVENTORY_ORDERS_ERROR, payload: error ? new Error(error) : undefined});

export function clearInventoryOrdersError()
{
    return dispatch => dispatch(getInventoryOrdersError(''));
}

export async function stopInventoryOrdersAction()
{
    await InventoryObservable.stopPolling();
    InventoryObservable = null as any;
}

export function getInventoryOrdersAction()
{
    return dispatch => {
        dispatch(getInventoryOrdersStarted());
        if(!InventoryObservable){
            InventoryObservable = GraphqlService.SendQueryObservable(Queries.GET_CUSTOMER_VEHICLE_WAREHOUSE_DETAILS);

            InventoryObservable.onResults.subscribe(async result => {
                if (result.error){
                    await InventoryObservable.stopPolling();
                    InventoryObservable = null as any;
                    return dispatch(getInventoryOrdersError(result.error.message));
                } else {
                    dispatch(getInventoryOrdersSuccess(result.data as warehouseOrder[]));
                }
            })
        } else {
            InventoryObservable.refetch();
        }
    }
}