import { gql } from "@apollo/client";
import { profileSP, tokenRes } from ".";
import { GraphqlService } from "../../graphql.service";
import { StandardResult } from "../vehicle_order";

export const LOGIN_CUSTOMER_PROFILE = gql`
query ($data: String!, $keepLogged: Boolean){
    login_customer_profile(data: $data, keepLogged: $keepLogged){
        ${tokenRes.QUERY}
    }
}`

export const VERIFY_PROFILE = gql`
query {
    verify_profile{
        ${profileSP.QUERY}
    }
}`

export const VERIFY_PASSWORD_TOKEN = gql`
query ($token: String){
    verify_password_token(token:$token){
        ${StandardResult.QUERY}
    }
}`

export function login_customer_profile(input: {data: string, keepLogged: boolean}): Promise<tokenRes>{
    return new Promise<tokenRes>(async (resolve, reject) => {
        try {
            let res = await GraphqlService.SendQuery(LOGIN_CUSTOMER_PROFILE, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in login_customer_profile: ', ex.message);
            reject(ex);
        }
    })
}

export function verify_profile(): Promise<profileSP> {
    return new Promise<profileSP>(async (resolve, reject) => {
        try {
            let res = await GraphqlService.SendQuery(VERIFY_PROFILE);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in verify_profile: ', ex.message);
            reject(ex);
        }
    })
}


export function verify_password_token(input:{token:string}): Promise<StandardResult> {
    return new Promise<StandardResult>(async (resolve, reject) => {
        try {
            let res = await GraphqlService.SendQuery(VERIFY_PASSWORD_TOKEN, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in verify_password_token: ', ex.message);
            reject(ex);
        }
    })
}