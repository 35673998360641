import React from 'react'
import Helper from "../../utilities/Helper";
import "./accountinfo.scss"

export const AccountInfo = () => {

  function handleUserLogout() {
    Helper.Session.DoLogout(false);
  }


  return (
    <div className="accountinfo">
      <div className="accountinfo-content">
        Account Info
      </div>
      <div className="mt-4">
        <div className="accountinfo-title">
          Account Name
        </div>
        <div className="accountinfo-content">
        {Helper.Session.getUser()?.customer?.name || ''}
        </div>
      </div>
      <div className="mt-4">
        <div className="accountinfo-title">
          Profile Name
        </div>
        <div className="accountinfo-content" >
          {Helper.Session.getUser()?.name || ''}
        </div>
      </div>
      <div className="mt-4">
        <div className="accountinfo-title">
          Profile Email
        </div>
        <div className="accountinfo-content">
        {Helper.Session.getUser()?.contact[0]?.value || ''}
        </div>
      </div>
      <div className="accountinfo-logout mt-52 cursor-pointer" onClick={() => handleUserLogout()}>
        Log Out
      </div>
    </div>
  )
}
