import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Helper from "../../utilities/Helper";
import { Queries } from "../../services/GraphQL/queries/index";
import { encrypt } from "../../services/ps-crypt";
import {
  Button,
  InputField,
  CheckBoxField,
} from "@profuse-studio/profuse-inventory-uix";

import "./login.scss";
import BasicLayout from "../../layout/BasicLayout/BasicLayout";

export const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");

  let path = window.location.href.split("/login/")[1];
  const [stayLoggedIn, setStayLoggedIn] = useState<boolean>(true);

  async function handleFormSubmit() {
    if (!validInputs()) {
      return;
    }
    Helper.SetLoading(true);
    try {
      setErrorText("");
      let data = encrypt({ email, password });
      let res = await Queries.login_customer_profile({
        data,
        keepLogged: stayLoggedIn,
      });

      if (!res || !res.success) {
        throw new Error(res ? res.message : "No connection to the server");
      }

      Helper.Session.NewLogin(res, path);
    } catch (ex: any) {
      console.log("Error in handleFormSubmit: ", ex.message);
      setErrorText(ex.message);
    }
    Helper.SetLoading(false);
  }

  function validInputs() {
    return password.length > 0 && Helper.Validators.IsValidEmail(email);
  }

  useEffect(() => {
    if (errorText) {
      setErrorText("");
    }
  }, [password, email]);

  return (
    <BasicLayout>
      <div className="login-component">
        <div className="logo">
          <img src="/JPL_logo.svg" className="login-img" alt="logo" />
        </div>

        <div className="row">
          <p className="title mb-2">Log in</p>
          <div className="col-12 mb-1-5">
            <InputField
              type="email"
              name="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e)}
              label={"Email"}
              onEnter={() => handleFormSubmit()}
              isValidated={(text) => {
                return !Helper.Validators.IsValidEmail(text)
                  ? "Invalid email"
                  : null;
              }}
            />
          </div>
          <div className="col-12" style={{ position: "relative" }}>
            <div className="password-reset"></div>
            <InputField
              type={`password`}
              name="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e)}
              label={"Password"}
              onEnter={() => handleFormSubmit()}
              rightLabel={
                <Link to="/forgotpassword" className="login-forgot-password">
                  Forgot password?
                </Link>
              }
              // isValidated={(text) => {return !Helper.Validators.IsValidPassword(text) ? 'Invalid Password' : null}}
            />
          </div>
          <div className="col-12">
            <CheckBoxField
              name={`string`}
              type={`checkbox`}
              label="Stay signed in"
              checked={true}
              onChange={() => setStayLoggedIn(!stayLoggedIn)}
            />
            {errorText ? (
              <p
                className="error-text mt-4 col-12 fs-22"
                style={{ color: "red" }}
              >
                {errorText}
              </p>
            ) : (
              // OR
              ""
            )}

            <div className="col-12 login-btn mt-2">
              <Button
                onClick={() => handleFormSubmit()}
                className={`primary w-100`}
                label={`Login`}
                type={"fill"}
                active={validInputs()}
                btnColor={"black"}
              />
            </div>
          </div>
        </div>

        <div className="logo mt-5">
          <p className="font-10 mt-2" style={{ opacity: "0.5" }}>
            - POWERED BY -
          </p>
          <img
            src="/Profuse_horizontal_black.png"
            style={{ width: "100%", cursor: "pointer" }}
            alt="Profuse Studio"
            onClick={() => window.open("https://www.profusestudio.com/")}
          />
        </div>
      </div>
    </BasicLayout>
  );
};
