import React, {useEffect, useState} from 'react';
import './MainLayout.scss'

interface MainLayoutProps {
  children: JSX.Element,
  className?: string
}

const MainLayout = (props: MainLayoutProps) => {

  return (
    <section className={`main-layout ${props.className ?? ''}`}>
      <main className="main-content flex-1-container">
        {props.children}
      </main>
    </section>
  );
};

export default MainLayout;
