

export class tokenRes {
    success: boolean = false;
    message: string = '';
    token: string = '';

    static QUERY = `
        success
        message
        token
    `
}

export class profileCustomerSP{
    id?: number;
    name: string = '';
    terms: string = '';

    static QUERY = `
    id
    name
    terms
    `
}

export class conRes{
    title: string = '';
    value: string = '';
    type: string = '';
    unsubscribed: boolean = false;

    static QUERY = `
    title
    value
    type
    unsubscribed
    `
}

export class profileSP{
    id?: number;
    role: string = '';
    customer_id?: number;
    customer: profileCustomerSP = new profileCustomerSP();
    name: string = '';
    title: string = '';
    contact: conRes[] = [];

    static QUERY = `
        id
        role
        customer_id
        customer{
            ${profileCustomerSP.QUERY}
        }
        name
        title
        contact{
            ${conRes.QUERY}
        }`
}