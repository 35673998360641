import useMediaQuery from "../../hooks/useMediaQuery";
import * as React from 'react';
import {createContext, useState} from "react";

export interface IGlobalProps {
  showMobileBottomTabs: boolean;
  mobileBottomTabsPosition: 'top' | 'bottom';
  saveMobileBottomTabs?: (state: boolean) => void;
  saveMobileBottomTabsPosition?: (position: 'top' | 'bottom') => void;
}

const defaultValues: IGlobalProps = {
  showMobileBottomTabs: true,
  mobileBottomTabsPosition: 'bottom',
  saveMobileBottomTabs: (state: boolean) => {},
  saveMobileBottomTabsPosition: (position: 'top' | 'bottom') => {}
}

export const GlobalContext = createContext<IGlobalProps>(defaultValues);

const GlobalProvider = ({children}: { children: JSX.Element }) => {
  const [showMobileBottomTabs, setShowMobileBottomTabs] = useState<boolean>(true);
  const [mobileBottomTabsPosition, setMobileBottomTabsPosition] = useState<'top' | 'bottom'>('bottom');

  const saveMobileBottomTabs = (state: boolean) => {
    setShowMobileBottomTabs(state);
  }

  const saveMobileBottomTabsPosition = (position: 'top' | 'bottom') => {
    setMobileBottomTabsPosition(position);
  }

  return <GlobalContext.Provider value={{ showMobileBottomTabs, saveMobileBottomTabs, mobileBottomTabsPosition,  saveMobileBottomTabsPosition}}>{children}</GlobalContext.Provider>;
}

export default GlobalProvider;

