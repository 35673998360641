export class carrierSP {
    name: string = '';
    driver: string = '';
    
    static QUERY = `
        name
        driver
    `;
}

export class Address{
    street_number: string = '';
    street: string = '';
    city: string = '';
    state: string = '';
    postal_code: string = '';
    country: string = '';
    postal_code_suffix: string = '';
    extra: string = '';

    static QUERY = `
        street_number
        street
        city
        state
        postal_code
        country
        postal_code_suffix
        extra
    `; 
}

export class moveSP {
    time: string = '';
    carrier: carrierSP = new carrierSP();
    note: string = '';
    location: Address = new Address();
    incoming: boolean = true;
    completed: boolean = false;
    
    static QUERY = `
        time
        carrier{
            ${carrierSP.QUERY}
        }
        note
        location{
            ${Address.QUERY}
        }
        incoming
        completed
    `;
}

export class Vehicle {
    id?: number;
    vin: string = '';
    year: string = '';
    make: string = '';
    model: string = '';
    submodel: string = '';
    color: string = '';
    license_plate: string = '';
    internal_note: string = '';

    static QUERY = `
        id
        vin
        year
        make
        model
        submodel
        color
        license_plate
        internal_note
    `;
}

export class SamsaraLocationInfo{
    time: string = '';
    latitude: number = 0;
    longitude: number = 0;
    heading: number = 0;
    speed: number = 0;
    reverseGeo: {formattedLocation: string} = {formattedLocation: ''}

    static QUERY = `
        time
        latitude
        longitude
        heading
        speed
        reverseGeo {
            formattedLocation
        }`;
 }

export class Samsara_vehicle {
    id: string = '';
    name: string = '';
    make: string = '';
    model: string = '';
    notes: string = '';
    serial: string = '';
    vin: string = '';
    year: string = '';
    licensePlate: string = '';
    location: SamsaraLocationInfo = new SamsaraLocationInfo();

    static QUERY = `
        id
        name
        make
        model
        notes
        serial
        vin
        year
        licensePlate
        location {${SamsaraLocationInfo.QUERY}}
    `;
}

export class VehicleHistory{
    last_move: string = '';
    vin: string = '';
    description: string = '';
    status: string = '';

    static QUERY = `
        last_move
        vin
        description
        status
        `
}

export class Warehouse{
    id: number
    name: string = '';
    address: Address = new Address();

    static QUERY = `
    id
    name
    address{${Address.QUERY}}
    `
 }

 export class InventoryOrder{
    vehicle_order_id: number
    warehouses: Warehouse[] = [];
    has_pending_inspection: boolean = false;
    has_inventory_services: boolean = false;
    moves: moveSP[] = [];

    static QUERY = `
    vehicle_order_id
    warehouses {${Warehouse.QUERY}}
    has_pending_inspection
    has_inventory_services
    moves {${moveSP.QUERY}}
    `
 }
