import { gql } from "@apollo/client";
import { InventoryOrder, Samsara_vehicle, Vehicle, VehicleHistory } from ".";
import { GraphqlService } from "../../graphql.service";

export const GET_VEHICLE_LOCATIONS = gql`
query{
    get_vehicle_locations{
        ${Samsara_vehicle.QUERY}
    }
}`

export const SEARCH_INVENTORY_VEHICLE_HISTORY = gql`
query ($vin: String, $description: String, $start_date: String, $end_date: String){
    search_inventory_vehicle_history(vin: $vin, description: $description, start_date: $start_date, end_date: $end_date){
        ${VehicleHistory.QUERY}
    }
}`

export const GET_VEHICLE_INVENTORY_ORDERS = gql`
query ($vin:String!){
    get_vehicle_inventory_orders(vin:$vin){
        ${InventoryOrder.QUERY}
    }
}`

export const GET_VEHICLE_DETAILS = gql`
query ($vin:String!){
    get_vehicle_details(vin: $vin){
        ${Vehicle.QUERY}
    }
}`

export const GET_VIN_LOCATION_STATIC_IMAGE = gql`
query ($vin:String!){
    get_vin_location_static_image(vin: $vin){
        id
        time
        link
        address
    }
}`

export async function get_vehicle_locations(): Promise<Samsara_vehicle[]> {
    return new Promise<Samsara_vehicle[]>(async (resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(GET_VEHICLE_LOCATIONS);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in get_vehicle_locations: ', ex.message);
            reject({message: ex.message});
        }
    })
}

export async function search_inventory_vehicle_history(input:{vin: string, description: string, start_date: string, end_date: string}): Promise<VehicleHistory[]> {
    return new Promise<VehicleHistory[]>(async (resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(SEARCH_INVENTORY_VEHICLE_HISTORY, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in search_inventory_vehicle_history: ', ex.message);
            reject({message: ex.message});
        }
    })
}

export async function get_vehicle_inventory_orders(input:{vin:string}): Promise<InventoryOrder[]>{
    return new Promise<InventoryOrder[]>(async (resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(GET_VEHICLE_INVENTORY_ORDERS, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in get_vehicle_inventory_orders: ', ex.message);
            reject({message: ex.message});
        }
    })
}

export async function get_vehicle_details(input:{vin:string}): Promise<Vehicle>{
    return new Promise<Vehicle>(async (resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(GET_VEHICLE_DETAILS, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in get_vehicle_details: ', ex.message);
            reject({message: ex.message});
        }
    })
}

export async function get_vin_location_static_image(input:{vin:string}): Promise<{id:string,time:string,link:string,address:string}>{
    return new Promise<{id:string,time:string,link:string,address:string}>(async (resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(GET_VIN_LOCATION_STATIC_IMAGE, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in get_vin_location_static_image: ', ex.message);
            reject({message: ex.message});
        }
    })
}
