import { combineReducers } from "redux";
import InventoryReducer, {IInventoryOrders} from "./inventory.reducer"
import VehicleDetailsReducer, {IVehicleDetails} from "./vehicleDetails.reducer"


const reducers = combineReducers({
    inventory: InventoryReducer,
    vehicleDetails: VehicleDetailsReducer
})

export interface AllStates {
    inventory: IInventoryOrders
    vehicleDetails: IVehicleDetails
}

export default reducers;