import { gql } from "@apollo/client";
import { vehicleWarehouseDetails, warehouseOrder, StandardResult } from ".";
import { GraphqlService } from "../../graphql.service";

export const GET_INSPECTION_REPORT_LINK = gql`
  query($id: Int!){
    get_inspection_report_link(id: $id) {
      ${StandardResult.QUERY}
    }
  }
`;

export const GET_VEHICLE_ORDER_WAREHOUSE_DETAILS = gql`
  query ($id: Int!) {
    get_vehicle_order_warehouse_details(id: $id) {
      ${vehicleWarehouseDetails.QUERY}
    }
  }
`;

export const GET_CUSTOMER_VEHICLE_WAREHOUSE_DETAILS = gql`
  query {
    get_customer_vehicle_warehouse_details {
      ${warehouseOrder.QUERY}
    }
  }
`;

export function get_vehicle_order_warehouse_details(input: {
  id: number;
}): Promise<vehicleWarehouseDetails> {
  return new Promise<vehicleWarehouseDetails>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(
        GET_VEHICLE_ORDER_WAREHOUSE_DETAILS,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_vehicle_order_warehouse_details: ", ex.message);
      reject(ex);
    }
  });
}

export function get_customer_vehicle_warehouse_details(): Promise<
  warehouseOrder[]
> {
  return new Promise<warehouseOrder[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(
        GET_CUSTOMER_VEHICLE_WAREHOUSE_DETAILS
      );
      resolve(res);
    } catch (ex: any) {
      console.log(
        "Error in get_customer_vehicle_warehouse_details: ",
        ex.message
      );
      reject({ message: ex.message });
    }
  });
}

export function get_inspection_report_link(input: {
  id: number;
}): Promise<StandardResult> {
  return new Promise<StandardResult>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(
        GET_INSPECTION_REPORT_LINK,
        input
      );
      console.log(res)
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_inspection_report_link: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
