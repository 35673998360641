import React, {useEffect, useRef, useState} from "react";
import {BaseLayout} from "../../layout";
import {GMap, MarkerClass} from "@profuse-studio/profuse-ui";
import {
  SearchField,
  BasicCard,
  FabButton,
  ICONS,
} from "@profuse-studio/profuse-inventory-uix";
import {
  MobileListItem
} from "@profuse-studio/profuse-ui";
import {Queries} from "../../services/GraphQL/queries";
import {any, string} from "prop-types";
import {
  SamsaraLocationInfo,
  Samsara_vehicle,
} from "../../services/GraphQL/schemas/vehicle";
import Helper from "../../utilities/Helper";
import {useParams} from "react-router";
import {
  GlobalContext,
  IGlobalProps,
} from "../../context/GlobalContext/GlobalContext";
import {
  IMediaQuery,
  MediaContext,
} from "../../context/MediaContext/MediaContext";
import {Link} from "react-router-dom";
import close from "../../../assets/images/close.svg";
import arrow from "../../../assets/images/arrow.svg";


import "./map.scss";
import moment from "moment";
import environments from "../../../environment/environment";

interface IVehicleListItem {
  id: string;
  description: string;
  vin: string;
  location: SamsaraLocationInfo;
}

export const Map = () => {
  let refID = useParams();
  const listRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<GMap | null>(null);
  const [searchData, setSearchData] = useState<boolean>(true);
  const [selectedID, setSelectedID] = useState<string>(refID.id || "");
  const [filteredOutIDs, setFilteredOutIDs] = useState<string[]>([]);
  const [vehicleListData, setVehicleListData] = useState<IVehicleListItem[]>(
    []
  );
  const vehicleData = useRef<IVehicleListItem[]>([]);

  const [selectedVehicleDetails, setSelectedVehicleDetails] =
    useState<IVehicleListItem>();
  const [toggleSelectedVehicleDetails, setToggleSelectedVehicleDetails] =
    useState<boolean>(false);

  const [toggleListView, setToggleListView] = useState<boolean>(false);

  const {isSm, isMd} = React.useContext(MediaContext) as IMediaQuery;
  const {saveMobileBottomTabs, saveMobileBottomTabsPosition} = React.useContext(
    GlobalContext
  ) as IGlobalProps;

  useEffect(() => {
    const m = new GMap({
      isDrawable: true,
      loadPlaces: true,
      mapId: "dae6a518c08f93ce",
      mapKey: environments.GMAP_KEY,
      onMapInit: () => {
        mapRef.current = m;
      },
    });
    fillData(true);
  }, []);

  useEffect(() => {
    if (saveMobileBottomTabsPosition) {
      saveMobileBottomTabsPosition('top');
    }

    if (toggleListView && saveMobileBottomTabs) {
      saveMobileBottomTabs(false);
    }

    return () => {
      if (saveMobileBottomTabsPosition) {
        saveMobileBottomTabsPosition('bottom');
      }

      if (saveMobileBottomTabs) {
        saveMobileBottomTabs(true);
      }
    };
  }, [toggleListView]);


  const VehicleDitailsWindow = useRef<HTMLDivElement>(null);

  const handleToggleMobileLitsView = () => {
    setToggleListView(!toggleListView);
    if(toggleSelectedVehicleDetails){
      setToggleSelectedVehicleDetails(!toggleSelectedVehicleDetails);
    }else {
      return
    }

  }

  const hndleVehicleDetailsWindow = () => {
    setToggleSelectedVehicleDetails(!toggleSelectedVehicleDetails);
    setSelectedID(" ");
  };

  const handleMobileListViewClick = (id: string) => {
    setToggleListView(!toggleListView);
    vehicleSelected(id)

  }

  const vehicleSelected = (id: string) => {
    setSelectedID(id);
    let selectedVehicle = vehicleData.current.find((x) => x.id === id);
    if (selectedVehicle) {
      mapRef.current?.centerMap({
        lat: selectedVehicle.location.latitude,
        lng: selectedVehicle.location.longitude,
      });
      setToggleSelectedVehicleDetails(true);
      const vehicleDetailsKframe = VehicleDitailsWindow.current;
      vehicleDetailsKframe?.classList.toggle("slide-in");
      setSelectedVehicleDetails(selectedVehicle);
    }
    Helper.Navigation.NavigateTo("/map/" + id);
    mapRef.current?.markersCollection.filter(m => m.marker.id != id && m.marker.selected).forEach((m: { marker: MarkerClass }) => {
      m.marker.selected = false;
      (m as any).reDraw(m.marker);
    });
    mapRef.current?.markersCollection.filter(m => m.marker.id == id).forEach((m: { marker: MarkerClass }) => {
      m.marker.selected = true;
      (m as any).reDraw(m.marker);
    });
  };

  useEffect(() => {
    listRef.current?.scrollIntoView({block: 'end', behavior: 'smooth'})
  }, [selectedID]);


  function getColor(code: string) {
    switch (code.toUpperCase()) {
      case "CU":
        return "#FF7F0F";
      case "PH":
        return "#42ACDE";
      case "GH":
        return "#74C336";
      default:
        return "green";
    }
  }

  function fillMap(data: Samsara_vehicle[]) {
    let markers =
      data?.map((x) => {
        return new MarkerClass({
          id: x.id,
          position: {
            lat: x.location?.latitude,
            lng: x.location?.longitude,
          },
          text: `<h1>${x.year || ""} ${x.make || ""} ${x.model || ""}</h1>
        <h4>Speed: ${x.location?.speed}</h4>
        <h4>Address: ${x.location?.reverseGeo?.formattedLocation || ""}</h4>
        <h4>At ${moment(new Date(x.location?.time)).format('MM/DD/YYYY h:mm a')} (${Helper.FORMAT.DaysAgo(x.location?.time)})</h4>`.trim(),
          tag: (x.model
              ? x.model.substring(0, 2)
              : x.make?.substring(0, 2)
          ).toUpperCase(),
          selected: x.id == selectedID,
          onClick: (marker) => {
            vehicleSelected(marker.id);
          },
          color: getColor(
            x.model ? x.model.substring(0, 2) : x.make?.substring(0, 2)
          ),
        });
      }) || [];
    mapRef.current?.drawMarkers(markers);
  }

  async function fillData(select: boolean = false) {
    setSearchData(false);
    if (!Helper.Navigation.GetCurrentLocation().startsWith("/map")) {
      return;
    }
    try {
      let data = await Queries.get_vehicle_locations();
      let list = data
        .sort((a, b) => ((a.model || "") > (b.model || "") ? -1 : 1))
        .map((x) => ({
          id: x.id,
          description: `${x.year || ""} ${x.make || ""} ${
            x.model || ""
          }`.trim(),
          vin: x.vin,
          location: x.location,
        }));
      setVehicleListData(list);
      vehicleData.current = [...list];
      fillMap(data);
      if (select) {
        vehicleSelected(selectedID);
      }
    } catch (ex: any) {
      console.log("Error in fillData: ", ex.message);
    }
    setTimeout(() => setSearchData(true), 2000);
  }

  function filterOut(search: string) {
    let searchTerms = search
      .toLocaleLowerCase()
      .split(" ")
      .filter((x) => x);
    let excluded: string[] = [];
    for (let t of searchTerms) {
      for (let v of vehicleListData) {
        if (!(v.description + v.vin).toLocaleLowerCase().includes(t)) {
          excluded.push(v.id);
        }
      }
    }
    setFilteredOutIDs(excluded);
  }

  useEffect(() => {
    if (searchData) {
      fillData();
    }
  }, [searchData]);


  return (
    <section className={`map-component flex-1-container`}>

      <div className="row flex-1-container row-container pb-24 ditails-window-position">
        <div className="col-12 col-sm-8 col-md-8 col-lg-9  d-flex">
          {/*Map*/}
          <section
            className={`${toggleListView ? "d-none  " : ""}`}
            id={`map`}
          ></section>
        </div>
        {
          <div className="col-12 col-sm-4 col-md-4 col-lg-3 d-flex">
            {/*Search*/}
            <div className="map-search w-100">
              <div className={`row`}>
                <div className="col-12 search-container flex-1-container z-i95">

                  {
                    (!isSm || toggleListView) ?
                      <div className={`row`}>
                        <SearchField
                          background={`#fff`}
                          onChange={(search) => filterOut(search)}
                        />
                      </div>
                      :
                      <></>
                  }

                  {/*Vehicles List Mode*/}
                  {toggleListView ? (
                    <>
                      <section className={`vehicles-list mt-2 flex-1-container`}>
                        {vehicleListData.filter((x) => !filteredOutIDs.includes(x.id)).map((item) => {
                          return (
                            <MobileListItem
                              iconText={item.description.split(" ").length > 2 ? item.description.split(" ")[3]?.split("")[0] : "RR" }
                              title={item.description}
                              subtitle = {item.vin}
                              description={`${item?.location?.reverseGeo?.formattedLocation || ""}`}
                              sideInfo={[`${Helper.FORMAT.DaysAgo(item?.location?.time || '')}`, `${moment(new Date(item?.location?.time || '')).format('h:mm a')}`]}
                              onClick={() => handleMobileListViewClick(item.id) }
                            />
                          );
                        })}
                      </section>
                    </>
                  ) : (
                    // OR
                    ""
                  )}

                  {/*Map List Mode*/}
                  {!toggleListView && !isSm ? (
                    <div className="search-results flex-1-container px-2">
                      {vehicleListData
                        .filter((x) => !filteredOutIDs.includes(x.id))
                        .map((x) => {
                          return (x.id === selectedID) ?
                            <div className="mt-3" ref={listRef}>
                              <BasicCard
                                current={{
                                  title: x.description,
                                  description: x.vin,
                                }}
                                selected={true}
                                onClick={() => {
                                  vehicleSelected(x.id);
                                }}
                              /></div>
                            :
                            <BasicCard
                              current={{
                                title: x.description,
                                description: x.vin,
                              }}
                              selected={false}
                              onClick={() => {
                                vehicleSelected(x.id);
                              }}
                            />
                        })}
                    </div>
                  ) : (
                    // OR
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        <div
          ref={VehicleDitailsWindow}
          className={
            toggleSelectedVehicleDetails
              ? "selected-vehicle-details-open mobile details_window"
              : " closed mobile details_window"
          }
        >
          <div className="container mt-3 ms-2 p-0 px-2">
            <div className="row">
              <div className="col-10 font-medium">{selectedVehicleDetails?.description}</div>
              <img
                src={close}
                className="col-2 justify-content-end align-self-center close d-flex"
                style={{height: "1rem"}}
                alt=""
                onClick={() => hndleVehicleDetailsWindow()}
              />
            </div>
            <div className="col-12 mt-1"> {selectedVehicleDetails?.vin} </div>
            <div className="row mt-1">
              <div className="fw400 row ">
                <div className="col-12 my-2">
                  Speed: {selectedVehicleDetails?.location.speed}
                  <img
                    className="mb-1 ms-3 col"
                    src={arrow}
                    style={{
                      transform: `rotate(${selectedVehicleDetails?.location.heading}deg)`,
                      height: "1.25rem",
                    }}
                    alt="diraction arrow"
                  />
                </div>
                <p
                  className="font-16 p-0 m-0 ms-2 mt-1 custom-size">{`${selectedVehicleDetails?.location?.reverseGeo?.formattedLocation || ""}`}</p>
                <p
                  className="font-16 p-0 m-0 ms-2 mt-2">{`At ${moment(new Date(selectedVehicleDetails?.location?.time || '')).format('MM/DD/YYYY h:mm a')} (${Helper.FORMAT.DaysAgo(selectedVehicleDetails?.location?.time || '')}`})</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row  mt-3">
              <div className="col justify-content-center view_more d-flex">
                <Link className="font-medium font-14" to={`/vehicle/${selectedVehicleDetails?.vin}`}>
                  VIEW DETAILS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*FabButton*/}
      {isSm ? (
        <FabButton
          icon={toggleListView ? ICONS.Map : ICONS.List}
          selected={toggleListView}
          onClick={() => {
             handleToggleMobileLitsView()
          }}
        />
      ) : (
        // OR
        ""
      )}
    </section>
  );
};

function useParam() {
  throw new Error("Function not implemented.");
}
