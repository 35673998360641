import React, { useEffect, useState } from "react";
import { Button, InputField } from "@profuse-studio/profuse-inventory-uix";
import "./forgotPassword.scss";
import {Mutations} from "../../services/GraphQL/mutations";
import { Link } from "react-router-dom";
import { BaseLayout } from "../../layout";
import Helper from "../../utilities/Helper";
import { Queries } from "../../services/GraphQL/queries";

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [endMessage, setEndMessage] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");

  async function handlePasswordReset() {
    Helper.SetLoading(true);
    try {
      if (!Helper.Validators.IsValidEmail(email)) {
        throw new Error("Invalid email");
      }
      let res = await Mutations.request_password_reset({email: email});
      //let res = await Queries.login_customer_profile({data: email, keepLogged:true});
      if (!res || !res.success) {
        throw new Error(res ? res.message : "No connection to the server");
      }
      setEndMessage(res.message);
    } catch (ex: any) {
      console.log("Error in handlePasswordReset: ", ex.message);
      setErrorText(ex.message);
    }
    Helper.SetLoading(false);
  }

  function validEmail() {
    return Helper.Validators.IsValidEmail(email);
  }

  useEffect(() => {
    setErrorText("");
  }, [email]);

  return (
    <BaseLayout>
      <div className="forgotPassword-component">
        <div
          className="logo"
          onClick={() => Helper.Navigation.NavigateTo("/login")}
        >
          <img
            src="/JPL_logo.svg"
            className="login-img"
            alt="logo"
          />
        </div>
        {endMessage ? (
          <div className="reset-successfully col-12">
            <p className="reset-successfully-text col-12">{endMessage}</p>
          </div>
        ) : (
          <div className="row">
            <p className="title mb-2">Recover Password</p>
            <div className="col-12">
              <InputField
                type="email"
                name="email"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e)}
                label={"Email"}
                // onEnter={() => handlePasswordReset()}
                isValidated={(text) => {
                  return !Helper.Validators.IsValidEmail(text)
                    ? "Invalid email"
                    : null;
                }}
              />
              <Link to="/login" className="label-sibling-link">
                Back to Login?
              </Link>
            </div>
            <div className="col-12">
              <p className="error-text mt-1 col-12">{errorText}</p>
              <div className="login-btn col-12 mt-2">
                <Button
                  btnColor="black"
                  label="Reset Password"
                  className="primary w-100"
                  type="fill"
                  onClick={() => handlePasswordReset()}
                  active={validEmail()}
                />
              </div>
            </div>
            <div className="logo mt-5">
              <p className="font-10 mt-2" style={{ opacity: "0.5" }}>
                - POWERED BY -
              </p>
              <img
                src="/Profuse_horizontal_black.png"
                style={{ width: "100%", cursor: "pointer" }}
                alt="Profuse Studio"
                onClick={() => window.open("https://www.profusestudio.com/")}
              />
            </div>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};
