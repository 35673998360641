import { Vehicle, moveSP } from "../vehicle";

export class inspectionSP{
    id: number;
    time: string = '';
    type: string = '';
    
    static QUERY = `
        id
        time
        type
    `;
}

export class Inventory_serviceSP{
    id?: number
    time_completed: string = '';
    time_start: string = '';
    time_end: string = '';
    completed: boolean = false;
    name: string = '';
    amount: number = 0.00;
    amount_remaining: number = 0.00;
    
    static QUERY = `
        id
        time_completed
        time_start
        time_end
        completed
        name
        amount
        amount_remaining
    `;
}

export class vehicleWarehouseDetails{
    id?: number
    current_location: string = '';
    vehicle: Vehicle = new Vehicle();
    moves: moveSP[] = [];
    last_move: moveSP = new moveSP();
    inspections: inspectionSP[] = [];
    inventory_services: Inventory_serviceSP[] = [];
    bay_name: string = '';
    
static QUERY = `
    id
    current_location
    vehicle {${Vehicle.QUERY}}
    moves {${moveSP.QUERY}}
    last_move {${moveSP.QUERY}}
    inspections {${inspectionSP.QUERY}}
    inventory_services {${Inventory_serviceSP.QUERY}}
    bay_name
`;
}


export class warehouseOrder{
    id?: number;
    name: string = '';
    parked: vehicleWarehouseDetails[] = [];
    incoming: vehicleWarehouseDetails[] = [];
    outgoing: vehicleWarehouseDetails[] = [];
    
    static QUERY = `
    id
    name
    parked {${vehicleWarehouseDetails.QUERY}}
    incoming {${vehicleWarehouseDetails.QUERY}}
    outgoing {${vehicleWarehouseDetails.QUERY}}
    `; 
}


export class StandardResult {
    id?: number;
    success: boolean = false;
    message: string = "";

    static QUERY = `
    id
    success
    message
    `;
}