import { StandardResult } from "../vehicle_order";
import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { tokenRes } from ".";

export const REQUEST_PASSWORD_RESET = gql`
mutation ($email: String){
    request_password_reset(email:$email){
        ${StandardResult.QUERY}
    }
}`

export const CHANGE_FORGOT_PASSWORD = gql`
mutation ($token: String, $data: String){
    change_forgot_password(token:$token, data:$data){
        ${tokenRes.QUERY}
    }
}`

export function request_password_reset(input:{email: string}): Promise<StandardResult>{
    return new Promise<StandardResult>(async (resolve, reject) => {
        try {
            let res = await GraphqlService.SendQuery(REQUEST_PASSWORD_RESET, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in request_password_reset: ', ex.message);
            reject(ex);
        }
    });
}

export function change_forgot_password(input:{token: string, data: string}): Promise<tokenRes>{
    return new Promise<tokenRes>(async (resolve, reject) => {
        try {
            let res = await GraphqlService.SendQuery(CHANGE_FORGOT_PASSWORD, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in change_forgot_password: ', ex.message);
            reject(ex);
        }
    });
}