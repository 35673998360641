import React, {useEffect, useRef, useState} from "react";
import "./inventory.scss";
import {useDispatch, useSelector} from "react-redux";
import {
  clearInventoryOrdersError,
  getInventoryOrdersAction,
  stopInventoryOrdersAction,
} from "../../redux/actions/inventory.action";
import {
  TableTabs,
  SearchField,
  Table,
  ICONS,
  IColumnProps,
  Svg,
  SideActionManager,
  VehicleListItem,
  MobileHeaderSearch,
  Dropdown,
  ClickOutsideListener
} from "@profuse-studio/profuse-inventory-uix";
import {
  MobileListItem
} from "@profuse-studio/profuse-ui";
import {vehicleWarehouseDetails, warehouseOrder} from "../../services/GraphQL/schemas/vehicle_order";
import {IInventoryOrders} from "../../redux/reducers/inventory.reducer";
import {AllStates} from "../../redux/reducers";
import Helper from "../../utilities/Helper";
import {Queries} from "../../services/GraphQL/queries";
import {InventorySideCard} from "../../components/InventorySideCard/InventorySideCard";
import moment from "moment";
import useWindowSize from "../../hooks/useWindowSize";
import {IMediaQuery, MediaContext} from "../../context/MediaContext/MediaContext";
import { moveSP } from "../../services/GraphQL/schemas/vehicle";

interface InventoryTableRow {
  id?: number;
  warehouse_name: string;
  vehicle: [string, string];
  date_time: [string, string, string];
  vin: string;
  carrier_driver: [string, string]
  location: [string, string]
  bay_name: [string, string]
}


export const Inventory = () => {
  const tableColumns = (selectedTab: "incoming" | "outgoing" | "parked"): IColumnProps[] => {
    switch (selectedTab) {
      case 'incoming':
        return [
          {
            flex: 2,
            label: "DATE/TIME",
            name: "date_time",
          },
          {
            flex: 5,
            label: "VEHICLE",
            name: "vehicle",
            sort_by: "vin",
          },
          {
            flex: 3,
            label: selectedWarehouseTab < 0 ? "WAREHOUSE" : "CARRIER / DRIVER",
            name: selectedWarehouseTab < 0 ? "warehouse_name" : "carrier_driver",
          },
          {
            flex: 5,
            label: "COMING FROM",
            name: "location",
          },
          {
            flex: 1,
            type: "react-component",
            label: "EXTRAS",
            name: "extras",
          },
        ];
      case 'outgoing' :
        return [
          {
            flex: 2,
            label: "DATE/TIME",
            name: "date_time",
          },
          {
            flex: 5,
            label: "VEHICLE",
            name: "vehicle",
            sort_by: "vin",
          },
          {
            flex: 3,
            label: selectedWarehouseTab < 0 ? "WAREHOUSE" : "CARRIER / DRIVER",
            name: selectedWarehouseTab < 0 ? "warehouse_name" : "carrier_driver",
          },
          {
            flex: 5,
            label: "GOING TO",
            name: "location",
          },
          {
            flex: 1,
            label: "EXTRAS",
            type: "react-component",
            name: "extras",
          },
          {
            flex: 2,
            label: "BAY #",
            name: "bay_name",
          },

        ];
      case 'parked':
        let res: any[] = [
          {
            flex: 2,
            label: "BAY #",
            name: "bay_name",
          },
          {
            flex: 5,
            label: "VEHICLE",
            name: "vehicle",
            sort_by: "vin",
          },
          {
            flex: 2,
            label: "SINCE",
            name: "date_time",
          },
          {
            flex: 1,
            label: "EXTRAS",
            type: "react-component",
            name: "extras",
          }
        ];
        if (selectedWarehouseTab < 0) {
          res.splice(2, 0, {
            flex: 3,
            label: "WAREHOUSE",
            name: "warehouse_name"
          });
        }
        return res;
    }
    ;
  }

  const INCOMING_TAB = 'incoming'
  const OUTGOING_TAB = 'outgoing'
  const PARKED_TAB = 'parked'

  const defaultTabs = {
    "incoming": {
      tab: INCOMING_TAB,
      value: 0,
      icon: ICONS.ArrowDown,
      color: '#EB4886'
    },
    "outgoing": {
      tab: OUTGOING_TAB,
      value: 1,
      icon: ICONS.ArrowUp,
      color: '#62D305'
    },
    "parked": {
      tab: PARKED_TAB,
      value: 2,
      icon: ICONS.Parked,
      color: '#17A8EB'
    }
  }

  type ITabOptions = 'incoming' | 'outgoing' | 'parked';

  const dispatch = useDispatch();
  const [width, height] = useWindowSize();
  const [showTableAsList, setShowTableAsList] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState<vehicleWarehouseDetails | null>(null);
  const [inventoryTableData, setInventoryTableData] = useState<InventoryTableRow[]>([]);
  const [selectedWarehouseTab, setSelectedWarehouseTab] = useState<number>(-1);
  const [selectedTableTab, setSelectedTableTab] = useState<ITabOptions>(INCOMING_TAB);
  const [search, setSearch] = useState<string>('');

  const {isSm, isMd} = React.useContext(MediaContext) as IMediaQuery;

  useEffect(() => {
    setShowTableAsList(width <= 450);
  }, [width]);

  const {loading, orders, error} = useSelector<AllStates>(
    (states) => states.inventory
  ) as IInventoryOrders;

  if (error) {
    console.log(
      "Error from Observable getInventoryOrdersAction: ",
      error.message
    );
    dispatch(clearInventoryOrdersError() as any);
  }

  const getTopBarTabs = () => {
    return [{index: -1, label: `All (${orders.map(i => i.parked.length).reduce((a,b) => a + b, 0)})`}, ...orders.map((x, i) => ({
      index: i,
      label: `${x.name} (${x.parked.length})`,
    }))]
  }

  const getDropDownList = () => {
    return [{name: `All (${orders.map(i => i.parked.length).reduce((a,b) => a + b, 0)})`, value: '-1'}, ...orders.map((x, i) => ({
      name: `${x.name} (${x.parked.length})`,
      value: `${i}`,
    }))]
  }

  const handleDropDownChange = (value) => {
    setSelectedWarehouseTab(value);
  }

  function getOrderMove(order: vehicleWarehouseDetails): moveSP {
    switch (selectedTableTab) {
      case "incoming":
        let moves1 = order.moves?.filter(x => x.incoming).sort((a, b) => new Date(a.time).getTime() > new Date(b.time).getTime() ? 1 : -1);
        return moves1[0];
      case 'outgoing':
        let moves2 = order.moves?.filter(x => !x.incoming).sort((a, b) => new Date(a.time).getTime() > new Date(b.time).getTime() ? 1 : -1);
        return moves2[0];
      case 'parked':
        return order.last_move
      default:
        return new moveSP();
    }
  }

  function calculateDateTimeString(order: vehicleWarehouseDetails): [string, string, string] {
    switch (selectedTableTab) {
      case "incoming":
        let moves1 = getOrderMove(order);
        let time1 = moves1 ? new Date(moves1.time) : new Date();
        return [moment(time1).format('MM/DD/YYYY'), moment(time1).format('HH:mm a'), Helper.FORMAT.USDate(time1, 'dd'),]
      case 'outgoing':
        let moves2 = getOrderMove(order);
        let time2 = moves2 ? new Date(moves2.time) : new Date();
        return [moment(time2).format('MM/DD/YYYY'), moment(time2).format('HH:mm a'), Helper.FORMAT.USDate(time2, 'dd')]
      case 'parked':
        let moves3 = getOrderMove(order);
        let time3 = moves3 ? new Date(moves3.time) : new Date();
        return [moment(time3).format('MM/DD/YYYY HH:mm a'), Helper.FORMAT.USDate(time3, 'dd'), '']
      default:
        return ['-', '-', '-']
    }

  }

  async function handleInspectionReportClicked(inspection_id: number){
    Helper.SetLoading(true);
    try {

      let res = await Queries.get_inspection_report_link({id: inspection_id})
      if(!res.success){
        throw new Error(res.message)
      } else {
       window.open(res.message)
      }
    } catch (ex: any) {
      console.log("Error in handleInspectionReportClicked :" + ex.message)
    }
    Helper.SetLoading(false);
  }

  async function GetVehicleDetails(row: InventoryTableRow) {
    Helper.SetLoading(true)
    try {
      let res = await Queries.get_vehicle_order_warehouse_details({id: row.id || -1});
      setVehicleDetails(res);
    } catch (ex: any) {
      console.log("Error in GetVehicleDitels: ", ex.message)

    }
    Helper.SetLoading(false)
  }

  useEffect(() => {
    let temp: InventoryTableRow[] = orders?.length
      ? (selectedWarehouseTab < 0 ?
        orders.map(warehouse => warehouse[selectedTableTab].map((x) => ({
          id: x.id,
          warehouse_name: warehouse.name,
          vehicle: [
            `${x.vehicle.year} ${x.vehicle.make} ${x.vehicle.model}`.trim(),
            `${x.vehicle.vin.substring(
              0,
              x.vehicle.vin.length - 6
            )} - ${x.vehicle.vin.substring(x.vehicle.vin.length - 6)}`
          ] as [string, string],
          extras: <>
          {x.inspections.length > 0 ? <Svg src={ICONS.Edit} className="me-1" onClick={() => {}}/> : <Svg src={ICONS.Edit} className="me-1 table-icon inactive"/> }
          {x.inventory_services.length > 0 ? <Svg src={ICONS.Engine} className="me-1" onClick={() => {}}/> : <Svg src={ICONS.Engine} className="me-1 table-icon inactive" />}
          </>,
          vin: x.vehicle.vin,
          date_time: calculateDateTimeString(x),
          carrier_driver: [getOrderMove(x).carrier].map(c => {
            return [c.name, c.driver]
          })[0] as [string, string],
          location: [getOrderMove(x).location].filter(c => c).map(c => {
            return [`${c.street_number}  ${c.street}`, `${c.city}, ${c.state} ${c.postal_code}`]
          })[0] as [string, string],
          bay_name: x.bay_name.split('\n') as [string, string],
          listTemplate: <>
            <div>
              <p className="font-12 font-medium">Carrier:</p>
              <p className="font-12 font-medium">Driver:</p>
            </div>
          </>
        }))).flat()
        :
        orders[selectedWarehouseTab][selectedTableTab].map((x) => ({
          id: x.id,
          warehouse_name: orders[selectedWarehouseTab].name,
          vehicle: [
            `${x.vehicle.year} ${x.vehicle.make} ${x.vehicle.model}`.trim(),
            `${x.vehicle.vin.substring(
              0,
              x.vehicle.vin.length - 6
            )} - ${x.vehicle.vin.substring(x.vehicle.vin.length - 6)}`,
          ],
          extras: <>
            {x.inspections.length > 0 ?  <Svg src={ICONS.Edit} className="me-1" onClick={() => {}}/> : <Svg src={ICONS.Edit} className="me-1 table-icon inactive"/>}
            {x.inventory_services.length > 0 ?  <Svg src={ICONS.Engine} className="me-1" onClick={() => {}}/> : <Svg src={ICONS.Engine} className="me-1 table-icon inactive"/> }
          </>,
          vin: x.vehicle.vin,
          date_time: calculateDateTimeString(x),
          carrier_driver: [getOrderMove(x).carrier].map(c => {
            return [c.name, c.driver]
          })[0] as [string, string],
          location: [getOrderMove(x).location].filter(c => c).map(c => {
            return [`${c.street_number}  ${c.street}`, `${c.city}, ${c.state} ${c.postal_code}`]
          })[0] as [string, string],
          bay_name: x.bay_name.split('\n') as [string, string]
        })))

      : [];
    //Filter from Search bar
    let searches = search.toLocaleLowerCase().split(' ').filter(x => x);
    if (searches.length) {
      temp = temp.filter(row => {
        for (let term of searches) {
          if (!JSON.stringify(Object.keys(row).map(o => row[o])).toLocaleLowerCase().includes(term)) {
            return false;
          }
        }
        return true;
      });
    }

    setInventoryTableData(temp);
  }, [orders, selectedWarehouseTab, selectedTableTab, search]);

  useEffect(() => {
    if (!loading) {
      dispatch(getInventoryOrdersAction() as any);
    }
    Helper.WhenLocationChanges = (link) => {
      if (link !== "/inventory") {
        stopInventoryOrdersAction();
      }
    };
  }, []);

  useEffect(() => {
    Helper.SetLoading(loading);
  }, [loading]);

  const getTableTabs = () => {
    if(selectedWarehouseTab < 0){
      return [
        {
          index: 0,
          label: `Incoming (${
            orders.map(i => i.incoming.length).reduce((a,b) => a + b, 0) || 0
          })`,
        },
        {
          index: 1,
          label: `Outgoing (${
            orders.map(i => i.outgoing.length).reduce((a,b) => a + b, 0) || 0
          })`,
        },
        {
          index: 2,
          label: `Parked (${
            orders.map(i => i.parked.length).reduce((a,b) => a + b, 0) || 0
          })`,
        },
      ]
    } else {
      return [
        {
          index: 0,
          label: `Incoming (${
            orders[selectedWarehouseTab]?.incoming?.length || 0
          })`,
        },
        {
          index: 1,
          label: `Outgoing (${
            orders[selectedWarehouseTab]?.outgoing?.length || 0
          })`,
        },
        {
          index: 2,
          label: `Parked (${
            orders[selectedWarehouseTab]?.parked?.length || 0
          })`,
        },
      ]

    }
  }

  const InventoryTableTabs = () => {
    return (
      <TableTabs
        tabs={getTableTabs()}
        onSelect={(tab) => {
          setSelectedTableTab(
            defaultTabs[Object.keys(defaultTabs)[tab]].tab
          );
        }}
        selected={defaultTabs[selectedTableTab]?.value}
      />
    )
  }
  return (
    <>
      {
        !isSm ?
          <section className="inventory-component flex-1-container">
            <section className="table-container mt-3 flex-1-container">
              {/*TopBar*/}
              <section className="top-bar justify-content-between d-flex">
                <div style={{minWidth: '200px'}}>
                  {
                    getTopBarTabs.length > 5 ?
                      <Dropdown options={getDropDownList()} onChange={handleDropDownChange} />
                      : // OR
                      <TableTabs
                        tabs={getTopBarTabs()}
                        onSelect={(tab) => {
                          setSelectedWarehouseTab(tab);
                        }}
                        selected={selectedWarehouseTab}
                      />
                  }
                </div>
                <div>
                  <SearchField placeholder={`Search for vehicles`} showClearButton={true} background={`#fff`}  onChange={(s: string) => setSearch(s)}/>
                </div>
              </section>
              {/*TableContainer*/}
              <section className="table-content mt-20 tablet-div flex-1-container">
                <div className={`row flex-1-container row-container pb-24`}>
                  <div className="col-12 col-md-8 d-flex">
                    <div className={`bg-white br-12 flex-1-container`}>
                      <div className={`pt-3 mb-4`}>
                        <InventoryTableTabs/>
                      </div>
                      <Table
                        showAsList={showTableAsList}
                        listItemTemplateKey={'listTemplate'}
                        columns={tableColumns(selectedTableTab)}
                        menuOptions={[{
                          label: 'See details',
                          icon: ICONS.Eye,
                          action: (row) => {Helper.Navigation.NavigateTo(`/vehicle/${row.selected.row.vin}`)
                          }
                        },]}
                        rowHeight={80}
                        rows={inventoryTableData}
                        tableHeight="70vh"
                        onScrollDown={() => {
                          console.log('onScrollDown');
                        }}
                        onScrollUp={() => {
                          console.log('onScrollUp');
                        }}
                        onRowClicked={(row: InventoryTableRow) => GetVehicleDetails(row)}
                      />
                    </div>
                  </div>

                  {
                    !isSm && !isMd ?
                      <div className="col-12 col-md-4 d-none d-md-flex flex-1-container">
                        <InventorySideCard data={vehicleDetails as vehicleWarehouseDetails} onInspectionReportClicked={(id: number) => handleInspectionReportClicked(id)}/>
                      </div>
                      : // OR
                      ''
                  }
                </div>
              </section>
            </section>
          </section>
          : // OR
          <section className={`inventory-component-mobile flex-1-container`}>
            <header className={`p-24 row`}>
              <MobileHeaderSearch search={{
                background: '#f5f5f5',
                showClearButton: true,
                onChange: (value) => {
                  setSearch(value);
                }
              }} dropdown={{
                options: getDropDownList(),
                onChange: (value) => {
                  handleDropDownChange(value)
                }
              }}/>
              <div className={`mt-20`}>
                <InventoryTableTabs/>
              </div>
            </header>

            <section className="item-list flex-1-container">
              {
                inventoryTableData.map(item => {
                  return (
                    <MobileListItem
                      key={Math.random()}
                      title={item.vehicle[0]}
                      subtitle = {item.vehicle[1]}
                      menuOptions={[
                        {
                          icon: ICONS.Eye,
                          label: 'See Details',
                          action: () => {Helper.Navigation.NavigateTo(`/vehicle/${item.vin}`)}
                        }
                      ]}
                      icon={defaultTabs[selectedTableTab]?.icon}
                      bgColor={defaultTabs[selectedTableTab]?.color}
                      description={item.carrier_driver.join(' ')}
                      sideInfo={item.date_time}
                      onLongClick={() => {
                        console.log('click pressed');
                      }}
                      onClick={() => {
                        GetVehicleDetails(item)
                      }}
                    />
                  )
                })
              }
            </section>

          </section>
      }

      {
        isSm || isMd ?
          <>
            <ClickOutsideListener onClickAway={()=>{setVehicleDetails(null)}}>
              <div>
                <SideActionManager opened={!!vehicleDetails?.id}>
                  <InventorySideCard
                    onMobileBack={()=>{setVehicleDetails(null)}}
                    data={vehicleDetails as vehicleWarehouseDetails} onInspectionReportClicked={(id: number) => handleInspectionReportClicked(id)}/>
                </SideActionManager>
              </div>
            </ClickOutsideListener>
          </>
          : // OR
          ''
      }
    </>
  );
};
