import { warehouseOrder } from '../../services/GraphQL/schemas/vehicle_order';
import IReducerAction from '../models/IReducerAction';
import {
    GET_INVENTORY_ORDERS_STARTED,
    GET_INVENTORY_ORDERS_SUCCESS,
    GET_INVENTORY_ORDERS_ERROR
} from '../types/inventory.type';

export interface IInventoryOrders {
    orders: warehouseOrder[],
    error?: Error,
    loading: boolean
}

const initialState: IInventoryOrders = {
    orders: [],
    error: undefined,
    loading: false
}

export default (state: IInventoryOrders = initialState, action: IReducerAction): IInventoryOrders => {
    switch (action.type) {
        case GET_INVENTORY_ORDERS_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_INVENTORY_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload
            }
        case GET_INVENTORY_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}