import { InventoryOrder } from '../../services/GraphQL/schemas/vehicle';
import IReducerAction from '../models/IReducerAction';
import {
    GET_VEHICLE_INVENTORY_ORDERS_STARTED,
    GET_VEHICLE_INVENTORY_ORDERS_SUCCESS,
    GET_VEHICLE_INVENTORY_ORDERS_ERROR
} from '../types/vehicleDetails.type';

export interface IVehicleDetails {
    orders: InventoryOrder[],
    error?: Error,
    loading: boolean
}

const initialState: IVehicleDetails = {
    orders: [],
    error: undefined,
    loading: false
}

export default (state: IVehicleDetails = initialState, action: IReducerAction): IVehicleDetails => {
    switch (action.type) {
        case GET_VEHICLE_INVENTORY_ORDERS_STARTED:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_INVENTORY_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload
            }
        case GET_VEHICLE_INVENTORY_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}